<template>
  <div>
    <div class="position-relative overflow-hidden p-3 m-5 text-center bg-main">
      <h3 class="display-9 p-5">We are almost there!</h3>
      <h5 class="display-9 text-muted">If you would like, we can notify you of beta release and get you
        early access.</h5>
      <div class="ml-embedded" data-form="cWFGRz" @submit="subscribe"></div>
    </div>
  </div>
</template>

<script>
(function (w, d, e, u, f, l, n) {
  w[f] = w[f] || function () {
    (w[f].q = w[f].q || [])
      .push(arguments);
  }, l = d.createElement(e), l.async = 1, l.src = u,
    n = d.getElementsByTagName(e)[0], n.parentNode.insertBefore(l, n);
})
  (window, document, 'script', 'https://assets.mailerlite.com/js/universal.js', 'ml');
ml('account', '26236');

import axios from 'axios'

export default {
  methods: {
    subscribe() {
      gtag_report_conversion()
    }
  }
}
</script>