import Vue from 'vue'
import VueRouter from 'vue-router'

import Home from '@/views/Home.vue'

import Pricing from '@/views/Pricing.vue'

import Subscribe from '@/views/Subscribe.vue'


import NotFound from '@/components/NotFound.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '*',
    name: 'NotFound',
    component: NotFound
  }, {
    path: '/',
    component: Home,
    meta: {
      auth: false
    }
  }, {
    path: '/pricing',
    component: Pricing,
    meta: {
      auth: false
    }
  },
  {
    path: '/subscribe',
    component: Subscribe,
    meta: {
      auth: false
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
