import Vue from 'vue'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import App from './App.vue'
import AppNav from './views/Nav.vue'
import router from './router'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import './css/style.css'

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBGQZvLVPUvNqbczi5T5o3dKuAs3rmDEBM",
  authDomain: "ezrendr.firebaseapp.com",
  projectId: "ezrendr",
  storageBucket: "ezrendr.appspot.com",
  messagingSenderId: "1008991395186",
  appId: "1:1008991395186:web:ab883a6d9a94ec090e11eb",
  measurementId: "G-FPGB55GGPQ"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

new Vue({
  router,
  render: (h) => h(App)
}).$mount('#app')

new Vue({
 el: '#appNav',
 render: (h) => h(AppNav)
});
